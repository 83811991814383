<template>
  <div class="d-flex w-100 overflow">
    <div class="overflow-scroll p-1">
      <div class="d-flex justify-content-between align-items-center mb-1">
        <b-button variant="outline-secondary" class="size-button-center" @click="$emit('slide_window')">
           <span><feather-icon icon="MenuIcon"/></span>
        </b-button>
        <b-button 
          variant="outline-secondary" 
          class="size-button-center m-5rem icon-setting-sider d-xl-none"
          @click="sidebar_chat_responsive = true"
        >
          <b-icon icon="gear-wide-connected" font-scale="1.3" variant="dark"></b-icon>
        </b-button>
      </div>
      <div class="h-100 flex-grow"  ref="main-container-chat">
        <div class="default-conversation" v-if="Object.keys(conversation_selected).length === 0">
          <div class="sender mb-2" :class="{'receiver': i === 2}" v-for="i in 3" :key="i" :style="`opacity: ${1 - 0.23 * i}`">
            <div class="avatar"></div>
            <div class="w-100 ml-2" :class="{'d-flex flex-column align-items-end mr-2': i === 2}">
              <div class="title-conversation"></div>
              <div class="message-bubble"></div>
            </div>
          </div>
        </div>

        <div class="conversation-chat" v-if="Object.keys(conversation_selected).length > 0">
          <div class="sender mb-2" :class="{'receiver': msg.role === 'user'}" v-for="(msg, index) in conversation_selected.messages" :key="index">
            <b-avatar class="avatar" :src="msg.role === 'user' ? image_profile : require('@/assets/images/icons/chat-bot.svg')"></b-avatar>
            <div class="w-100 ml-2" :class="{'d-flex flex-column align-items-end mr-2': msg.role === 'user'}">
              <div class="title-conversation w-100">
                <div v-if="msg.role === 'assistant'" class="d-flex justify-content-between align-items-start">
                  <span class="avenir-medium cursor-pointer d-block" @click="copyAction(msg.content)">{{$t('ia.chat.copy')}}</span>
                  <b-dropdown
                    no-caret
                    right
                    variant="link"
                    class="avenir-medium"
                    id="dropdown-options-chat"
                    disabled
                  > 
                    <template #button-content>{{$t('ia.chat.actions')}}
                      <feather-icon icon="ChevronDownIcon"></feather-icon>
                    </template> 
                    <b-dropdown-item>Rephrase</b-dropdown-item>
                    <b-dropdown-item>Elaborate</b-dropdown-item>
                    <b-dropdown-item>Summarize</b-dropdown-item>
                    <b-dropdown-item>Regenerate</b-dropdown-item>
                    <b-dropdown-item>Export</b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>
              <div class="message-bubble">
                {{ msg.content }}
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="background-input-botton">
        <small class="small-text">{{$t('ia.chat.textError')}}</small>
        <div class="d-flex justify-content-between align-items-center div-input-button">
          <b-form-input autocomplete="off" :placeholder="placeholder_text" class="size-input" v-model="query" @keyup.enter="chat" ref="textInput" />
          <b-button
            class="generate-img-ai"
            v-if="!loader_chat"
            @click="chat"
            :disabled="!query"
          >
          <feather-icon icon="SendIcon" class="send-icon"></feather-icon>
          <b-badge class="badge-button-ia">
            <feather-icon icon="CodesandboxIcon" class="icon-ia"></feather-icon>
            {{ estimated_credits }}
          </b-badge>
          </b-button>
          <b-skeleton v-else type="button" class="generate-img-ai"></b-skeleton>
        </div>
      </div>
    </div>

    <b-card class="card-chat-history h-100 d-none d-xl-block">
      <b-button variant="blue-button" class="blue-button w-100" @click="startNewChat()">{{ $t('ia.chat.newChat')}}</b-button>
      <div class="border-top mt-2 pt-2 overflow-scroll-y">
        <div 
          class="chat-list"
          :ref="`chat-list-${index}`"
          v-for="(i, index) in conversations"
          :key="i.uuid"
          @click="openConversation(i, index)"
        >
          <div class="d-flex w-100">
            <div :ref="`title-container-${index}`" class="w-100">
              <div class="d-flex justify-content-between w-100">
                <div class="d-flex">
                  <feather-icon icon="MessageSquareIcon" class="icon-chat-list"></feather-icon>
                  <span class="text-title-span">
                    {{ i.title }}
                  </span>
                </div>
                <div v-if="isActiveList(index) && !is_editing_title" class="d-flex">
                  <feather-icon icon="EditIcon" class="icon-chat-list mr-1" @click="editTitle(index)"></feather-icon>
                  <feather-icon icon="TrashIcon" class="icon-chat-list disabled"></feather-icon>
                </div>
              </div>
            </div>
            <div v-if="isActiveList(index)" class="w-auto">
              
              <div v-if="is_editing_title" class="d-flex align-items-center">
                <b-form-input ref="form-input-title" v-model="title_chat"></b-form-input>
                <feather-icon icon="CheckIcon" class="icon-chat-list mr-1 ml-1" @click="editTitleAction(i, index)"></feather-icon>
                <feather-icon icon="XIcon" class="icon-chat-list" @click="cancelEdit(index)"></feather-icon>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-card>
    <b-sidebar v-model="sidebar_chat_responsive" backdrop shadow right>
      <div class="p-1 card-chat-history h-100">
        <b-button variant="blue-button" class="blue-button w-100" @click="startNewChat()">{{ $t('ia.chat.newChat')}}</b-button>
        <div class="border-top mt-2 pt-2 overflow-scroll-y">
          <div 
            class="chat-list"
            :ref="`chat-list-${index}`"
            v-for="(i, index) in conversations"
            :key="i.uuid"
            @click="openConversation(i, index)"
          >
            <div class="d-flex w-100">
              <div :ref="`title-container-${index}`" class="w-100">
                <div class="d-flex justify-content-between w-100">
                  <div class="d-flex">
                    <feather-icon icon="MessageSquareIcon" class="icon-chat-list"></feather-icon>
                    <span class="text-title-span">
                      {{ i.title }}
                    </span>
                  </div>
                  <div v-if="isActiveList(index) && !is_editing_title" class="d-flex">
                    <feather-icon icon="EditIcon" class="icon-chat-list mr-1" @click="editTitle(index)"></feather-icon>
                    <feather-icon icon="TrashIcon" class="icon-chat-list disabled"></feather-icon>
                  </div>
                </div>
              </div>
              <div v-if="isActiveList(index)" class="w-auto">
                
                <div v-if="is_editing_title" class="d-flex align-items-center">
                  <b-form-input ref="form-input-title" v-model="title_chat"></b-form-input>
                  <feather-icon icon="CheckIcon" class="icon-chat-list mr-1 ml-1" @click="editTitleAction(i, index)"></feather-icon>
                  <feather-icon icon="XIcon" class="icon-chat-list" @click="cancelEdit(index)"></feather-icon>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </b-sidebar>
  </div>
</template>
    
<script>

import {
  BFormInput,
  BBadge,
  BButton,
  BSkeleton,
  BCard,
  BAvatar,
  BDropdown,
  BDropdownItem,
  BSidebar,
} from 'bootstrap-vue';
import { chat, getConversations, editTitleChat } from '@/services/ia';
import { nextTick } from '@vue/composition-api';
import config from '@/services/config';
import { getUserData } from '@/libs/utils/user';
import service from "@/services/dashboard";

const loadToastificationContent = () => import('@core/components/toastification/ToastificationContent.vue');

export default {
  name: 'chat',
  components: {
    BFormInput,
    BButton,
    BBadge,
    BSkeleton,
    BCard,
    BAvatar,
    BDropdown,
    BDropdownItem,
    BSidebar,
  },
  data() {
    return {
      image_profile: this.getImgProfile(getUserData().profile_image),
      loader_chat: false,
      conversations: [],
      conversation_selected: {},
      placeholder_text: '',
      query: '',
      cancel_token_estimated: null,
      axios: null,
      estimated_credits: 0,
      is_editing_title: false,
      title_chat: '',
      timeout: null,
      sidebar_chat_responsive: false,
      user: ''
    }
  },
  props: {

  },
  async mounted() {
    const placeholder_text = this.$t('creator.sendMessage');
    const speed = 50;
    this.animatePlaceholder(placeholder_text, speed);
    this.$refs.textInput.$el.addEventListener('focus', () => {
      this.animatePlaceholder(placeholder_text, speed);
    });
    this.axios = (await import('axios')).default;

    setTimeout(() => {
      this.getConversations()
    }, 1000);
  },
  created() {
    this.$root.$on('chatgpt_response_ws', (data) => {
      this.loader_chat = false;
      this.conversation_selected = data.body;
      const index = this.conversations.findIndex(i => i.uuid === data.body.uuid)      
      this.conversations[index] = data.body;
      setTimeout(() => {
        nextTick(() => { this.scrollToBottomInChatLog() })
      }, 1)
    })
  },
  methods: {
    getImgProfile(image_url) {
      if (image_url !== null && image_url !== "")
        return service.getCore + image_url;
      const img = require(`@/assets/images/avatars/${
        Math.floor(Math.random() * 4) + 1
      }.png`);
      return img;
    },
    async typeWriter(text, index, speed) {
      if (index < text.length) {
        this.placeholder_text += text.charAt(index);
        await this.sleep(speed);
        await this.typeWriter(text, index + 1, speed);
      }
    },
    async animatePlaceholder(text, speed) {
      this.placeholder_text = '';
      await this.typeWriter(text, 0, speed);
    },
    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    startNewChat() {
      this.conversation_selected = {};
      this.clearClassesForClick();
    },
    estimationCredits() {
      const CancelToken = this.axios.CancelToken;
      if (this.cancel_token_estimated) this.cancel_token_estimated()
      const self = this;
      this.axios({
        method: 'post',
        url: `${config.urlCore}/api/v3/ai/estimated-credits/`,
        data: {
          text: this.query,
          model: 'gpt-3.5-turbo'
        },
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
        cancelToken: new CancelToken(function executor(c) {
          self.cancel_token_estimated = c;
        })
      }).then((response) => {
        this.estimated_credits = response.data.response.estimated_credits;
      })
    },
    chat() {
      const estimated_credits =  this.estimated_credits
      if (this.conversation_selected.uuid) {
        this.conversation_selected.messages.push({role: 'user', content: this.query})
        setTimeout(() => {
          nextTick(() => { this.scrollToBottomInChatLog() })
        }, 1)
      }
      this.loader_chat = true;
      const data = {
        model: 'gpt-3.5-turbo',
        text: this.query,
        prev_chat: this.conversation_selected.uuid
      }
      this.query = '';

      chat(data).then((response) => {
        this.$emit('update_credits', estimated_credits);
        this.$root.$emit('update_credits_drop', estimated_credits)
        if (!this.conversations.find(i => i.uuid === response.uuid)) {
          response.title = response.messages[0].content;
          this.conversations.unshift(response)
          setTimeout(() => {
            this.openConversation(response, 0)
          }, 100);
        }
      })
    },
    getConversations() {
      getConversations({}).then((response) => {
        this.conversations = response.results;
        const chat_uuid = this.$route.params.uuid;
        
        if (chat_uuid) { 
          const index = this.conversations.findIndex(chat => chat.uuid === chat_uuid);
          this.openConversation(this.conversations.find(chat => chat.uuid === chat_uuid), index);
        }
      })
    },
    clearClassesForClick() {
      for (let index = 0; index < this.conversations.length; index++) {
        this.$refs[`chat-list-${index}`][0].classList.remove('active-chat-list')
      }
    },
    openConversation(conversation, index) {
      this.$router.push({ name: 'brandme_AI', params: { section: 'chat', category: 'chat_gpt', uuid: conversation.uuid}}).catch(() => {})
      this.conversation_selected = conversation;
      this.clearClassesForClick()
      this.$refs[`chat-list-${index}`][0].classList.add('active-chat-list')
      setTimeout(() => {
        nextTick(() => { this.scrollToBottomInChatLog() })
      }, 1)
    },
    isActiveList(index) {
      if (this.$refs[`chat-list-${index}`]) return this.$refs[`chat-list-${index}`][0].classList.contains('active-chat-list')
      return false
    },
    
    editTitle(index) {
      this.is_editing_title = true;
      this.title_chat = this.conversation_selected.title;
      this.hideTitle(index)
      
    },
    hideTitle(index) {
      for (let i = 0; i < this.conversations.length; i++) {
        const container = this.$refs[`title-container-${i}`][0]
        container.classList.remove('d-none');
      }
      this.$refs[`title-container-${index}`][0].classList.add('d-none');
    },
    cancelEdit(index) {
      const container = this.$refs[`title-container-${index}`][0]
      container.classList.remove('d-none');
      this.is_editing_title = false
    },
    editTitleAction(chat, index) {
      editTitleChat(chat.uuid, {title: this.title_chat}).then((response) => {
        chat.title = response.title;
        this.cancelEdit(index);
      })
    },
    scrollToBottomInChatLog() {
      const main_container = this.$refs['main-container-chat']
      main_container.scrollTop = main_container.scrollHeight;
    },
    copyAction(msg) {
      this.$copyText(msg).then(() => {
        loadToastificationContent().then((module) => {
          this.$toast({
            component: module.default,
            props: {
              title: this.$t('ia.chat.msjCopy'),
              icon: "CopyIcon",
              text: '',
              variant: 'success'
            },
          });
        })
      });
    },
  },
  watch: {
    query(val) {
      if (val) {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(this.estimationCredits(), 300);
      } else if (val === '') this.estimated_credits = 0;
    }
  }
}
</script>
<style lang="scss" scoped>
.size-input::placeholder {
  color: #70747c;
}
.overflow {
  height: 100%;
}
.overflow-scroll-y {
  height: 92%;
  overflow-y: scroll;
}
.default-conversation {
  width: 100%;
  .sender  {
    display: flex;
    width: 100%;
    .avatar {
      width: 42px;
      height: 42px;
      border-radius: 100%;
      background-color: #E9ECEF;
      flex-shrink: 0;
    }
    .title-conversation {
      width: 110px;
      height: 20px;
      margin-bottom: 0.4em;
      background-color: #E9ECEF;
    }
    .message-bubble {
      width: 95%;
      height: 115px;
      background-color: #e9ecef;
      border-radius: .5em;
      position: relative;

      &::before {
        border: 10px solid#e9ecef;
        content: '';
        border-left-color: transparent;
        border-bottom-color: transparent;
        border-right-color: transparent ;
        position: absolute;
        left: -10px;
      }
    }
  }
  .receiver {
    flex-direction: row-reverse;
    .message-bubble  {
      &::after {
        border: 10px solid #e9ecef !important;
        content: '' !important;
        border-left-color: transparent !important;
        border-bottom-color: transparent !important;
        border-right-color: transparent !important;
        border-top-color: transparent;
        position: absolute !important;
        right: -10px !important;
      }
      &::before {
        border: 10px solid transparent !important;
        content: '' !important;
      }
    }
  }
}
.flex-grow {
  flex-grow: 1;
  overflow-y: scroll;
  overflow-x: hidden;
}
.conversation-chat {

  width: 100%;
  height: 100% !important;
  .sender  {
    display: flex;
    width: 100%;
    .avatar {
      width: 42px;
      height: 42px;
      border-radius: 100%;
      background-color: #E9ECEF;
      flex-shrink: 0;
    }
    .title-conversation {
      width: 95% !important;
      height: 20px;
      margin-bottom: 0.4em;
    }
    .message-bubble {
      width: 95%;
      height: auto;
      background-color: #e9ecef;
      border-radius: .5em;
      position: relative;
      color: black;
      font-family: 'avenir-medium';
      padding: 1.6em;
      white-space: pre-line;

      &::before {
        border: 10px solid#e9ecef;
        content: '';
        border-left-color: transparent;
        border-bottom-color: transparent;
        border-right-color: transparent ;
        position: absolute;
        top: 0;
        left: -10px;
      }
    }
  }
  .receiver {
    flex-direction: row-reverse;
    // margin-top: -em;
    .message-bubble  {
      background-color: #55a6c4;
      color: white;
      &::after {
        border: 10px solid #55a6c4 !important;
        content: '' !important;
        border-left-color: transparent !important;
        border-bottom-color: transparent !important;
        border-right-color: transparent !important;
        border-top-color: transparent;
        position: absolute !important;
        right: -10px !important;
        top: 0;
      }
      &::before {
        border: 10px solid transparent !important;
        content: '' !important;
      }
    }
  }
}

.overflow-scroll {
  // overflow-y: scroll;
  height: 100%;
  overflow-x: hidden;
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  // justify-content: flex-end;
  .mr-05 {
    margin-right: 0.5em;
  }
  .size-button-center {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1rem;
    height: 3rem;
  }
  .background-input-botton {
    width: 100%;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    .small-text {
      background-color: #eeeeee;
      padding: 0.2em 0.5em;
      font-family: 'avenir-light';
      border-radius: 1em;
      margin: 0.4em 0;
    }
    .div-input-button {
      width: 100%;
      backdrop-filter: blur(2px);
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 0.5rem;
      padding: 0.5em;
      .send-icon {
        height: 20px;
        width: 20px;
        margin-right: 0.5em;
      }
      .badge-button-ia {
        display: flex;
        align-items: center;
        margin-left: 0.5em;
        line-height: 10px;
        background-color: #40736E;
      .icon-ia {
          width: 14px;
          height: 14px;
          margin-right: 0.2em;
        }
      }
      .generate-img-ai{
        height: 42px;
        display: flex;
        align-items: center;
        background: linear-gradient(to left bottom, rgb(124, 214, 175), rgb(62, 123, 160));
      }
      .size-input{
        background-color: transparent;
        height: 100%;
        width: 80%;
        font-size: 15px;
        font-weight: bold;
        border: none;
        box-shadow: none !important;
        font-family: monospace !important;

        @media(max-width: 500px) {
          font-size: 14px;
        }
      }
    }
  }
}
.card-chat-history {
  width: 290px;
  flex-shrink: 0;
  margin: 0;
  overflow-y: scroll;
  // padding-bottom: 100px;
  .chat-list {
    // background-color: #dee2e6;
    padding: 1em;
    border-radius: 0.5em;
    font-family: 'avenir-medium';
    display: flex;
    position: relative;

    .actions-chat-list {
      box-shadow: 10px 10px 10px #fff;
      height: 100%;
      display: flex;
      align-items: center;
      padding: 0 0.5em;
    }

    &:hover {
      background-color: #dee2e6af;
      cursor: pointer;
    }
    .icon-chat-list {
      width: 20px;
      height: 20px;
      display: block;

      &.disabled {
        color: #bcbaba;
      }
    }
    .text-title-span {
      margin-left: 0.5em;
      width: auto;
      max-width: 150px;
      display: block;
      white-space: nowrap;
      overflow-x: hidden;
      text-overflow: ellipsis;
    }
  }
  .active-chat-list {
    background-color: #dee2e6 !important;
  }
}
</style>
<style lang="scss">
#dropdown-options-chat {
  .btn {
    padding: 0 !important;
    color: black;
  } 
}
</style>
